import Login from "@/views/Login";
import Library from "@/views/Library";
import Cards from "@/views/Cards";
import Card from "@/views/Card";
import Report from "@/views/Report";
import MassActions from "@/views/MassActions";
import Groups from "@/views/Groups";
import Matrix from "@/views/Matrix";
import Home from "@/componentsCompany/smz/views/Home.vue";
import Evaluations from "@/componentsCompany/smz/views/Evaluations.vue";
import Calibration from "@/componentsCompany/smz/views/Calibration.vue";
import DynamicSettings from "@/views/settings/DynamicSettings.vue";
import Chiefs from "@/views/settings/Chiefs.vue";
import Rbac from "@/views/settings/Rbac.vue";
import Scales from "@/views/settings/Scales.vue";
import ScaleUnits from "@/componentsCompany/smz/views/ScaleUnits.vue";
import Successors from "@/componentsCompany/smz/views/Successors.vue";
import HumanResourcesEvaluation from "@/componentsCompany/smz/views/HumanResourcesEvaluation.vue";
import HumanResourcesMatrix from "@/componentsCompany/smz/views/HumanResourcesMatrix.vue";
import CompensationProcedures from "@/componentsCompany/smz/views/compensation/CompensationProcedures.vue";
import CompensationProcedureEdit from "@/componentsCompany/smz/views/compensation/CompensationProcedureEdit.vue";
import CompensationProcedureView from "@/componentsCompany/smz/views/compensation/CompensationProcedureView.vue";
import CompensationProcedureTree from "@/componentsCompany/smz/views/compensation/CompensationProcedureTree.vue";
import CompensationProcedurePlans from "@/componentsCompany/smz/views/compensation/CompensationProcedurePlans.vue";
import HomeSettings from "@/componentsCompany/smz/views/HomeSettings.vue";
import CompensationProcedureReport from "@/componentsCompany/smz/views/compensation/CompensationProcedureReport.vue";
import Deputies from "@/views/settings/Deputies.vue";


export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: 'Домашняя страница'}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Логин', layout: 'empty-layout'}
    },
    {
        path: '/library/:type([\\w]+)',
        name: 'Library',
        component: Library,
        meta: {title: 'Библиотека'}
    },
    {
        path: '/cards',
        name: 'Cards',
        component: Cards,
        meta: {title: 'Карты'}
    },
    {
        path: '/card/:id(\\d+)',
        name: 'Card',
        component: Card,
        meta: {title: 'Карта'}
    },
    {
        path: '/report',
        name: 'Report',
        component: Report,
        meta: {title: 'Конструктор отчетов'}
    },
    {
        path: '/matrix',
        name: 'Matrix',
        component: Matrix,
        meta: {title: 'Навигатор по целям подчинённых'}
    },



    {
        path: '/settings/deputies',
        name: 'Deputies',
        component: Deputies,
        meta: {title: 'Делегирование доступа'}
    },
    {
        path: '/settings/dynamic',
        name: 'DynamicSettings',
        component: DynamicSettings,
        meta: {title: 'Настройки'}
    },
    {
        path: '/settings/chiefs',
        name: 'Chiefs',
        component: Chiefs,
        meta: {title: 'Оргструктура'}
    },
    {
        path: '/settings/rbac',
        name: 'Rbac',
        component: Rbac,
        meta: {title: 'RBAC'}
    },
    {
        path: '/settings/scales',
        name: 'Scales',
        component: Scales,
        meta: {title: 'Шкалы'}
    },
    {
        path: '/settings/groups',
        name: 'Groups',
        component: Groups,
        meta: {title: 'Группы'}
    },
    {
        path: '/settings/mass-actions',
        name: 'MassActions',
        component: MassActions,
        meta: {title: 'Массовые действия'}
    },
    {
        path: '/catalog/units',
        name: 'Units',
        component: ScaleUnits,
        meta: {title: 'Единицы измерения'}
    },
    {
        path: '/catalog/evaluations',
        name: 'Evaluations',
        component: Evaluations,
        meta: {title: 'Оценки'}
    },
    {
        path: '/calibration',
        name: 'Calibration',
        component: Calibration,
        meta: {title: 'Калибровка оценок'}
    },
    {
        path: '/successors',
        name: 'Successors',
        component: Successors,
        meta: {title: 'Планы преемственности'}
    },
    {
        path: '/human-resources-evaluation',
        name: 'HumanResourcesEvaluation',
        component: HumanResourcesEvaluation,
        meta: {title: 'Оценка кадрового потенциала'}
    },
    {
        path: '/human-resources-matrix/:id(\\d+)',
        name: 'HumanResourcesMatrix',
        component: HumanResourcesMatrix,
        meta: {title: 'Матрица эффективности и потенциала'}
    },


    {
        path: '/compensation/procedures',
        name: 'CompensationProcedures',
        component: CompensationProcedures,
        meta: {title: 'Управление процессами'}
    },
    {
        path: '/compensation/procedure/plans',
        name: 'CompensationProcedurePlans',
        component: CompensationProcedurePlans,
        meta: {title: 'Планы'}
    },
    {
        path: '/compensation/procedure/report',
        name: 'CompensationProcedureReport',
        component: CompensationProcedureReport,
        meta: {title: 'Отчеты'}
    },
    {
        path: '/compensation/procedure/edit/:id(\\d+)',
        name: 'CompensationProcedureEdit',
        component: CompensationProcedureEdit,
        meta: {title: 'Редактирование процедуры'}
    },
    {
        path: '/compensation/procedure/tree/:id(\\d+)',
        name: 'CompensationProcedureTree',
        component: CompensationProcedureTree,
        meta: {title: 'Иерархия процедуры'}
    },
    {
        path: '/compensation/procedure/view/:procedure_id(\\d+)/:owner_id(\\d+)',
        name: 'CompensationProcedureView',
        component: CompensationProcedureView,
        meta: {title: 'Просмотр процедуры'}
    },


    {
        path: '/settings/home',
        name: 'HomeSettings',
        component: HomeSettings,
        meta: {title: 'Настройка главной страницы'}
    },
]
