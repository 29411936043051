<template>
  <div v-if="true">
    <page-heading
      heading="Планы"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>
    <el-card>

      <el-table
        ref="proceduresTable"
        style="width: 100%"
        :data="procedures"
        empty-text="Нет планов"
        v-loading="loading"
        :row-key="(row) => row.id"
        @sort-change="sortChanged($event, loadProcedures)"
      >
        <el-table-column label="Название процедуры" prop="name" v-slot="scope" sortable="custom">
          <router-link
            :to="{ name: 'CompensationProcedureView', params: { procedure_id: scope.row.id, owner_id:scope.row.current_user_procedure.user_id } }"
            target="_blank"
          >
            {{ scope.row.name }}
          </router-link>
        </el-table-column>
        <el-table-column label="Тип процедуры" v-slot="scope" prop="type" sortable="custom">
          {{procedureTypes[scope.row.type]}}
        </el-table-column>
        <el-table-column label="Год" width="100" prop="year" sortable="custom">
        </el-table-column>
        <el-table-column label="Период" v-slot="scope" width="250">
          {{ new Date(scope.row.date_start).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
           -
          {{ new Date(scope.row.date_end).toLocaleDateString($companyConfiguration.helpers.getDateLocale()) }}
        </el-table-column>
        <el-table-column label="Участники" v-slot="scope" width="150" prop="employees_of_current_user_count">
          {{scope.row.employees_of_current_user_count}}
        </el-table-column>
        <el-table-column label="Статус процедуры" v-slot="scope" width="250" prop="status">
          {{ procedureStatuses[scope.row.status] }}
        </el-table-column>
        <el-table-column label="Статус согласования" v-slot="scope" width="250" prop="status">
          <div v-if="scope.row.type === 'year_bonus'"> - </div>
          <div v-else>{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[scope.row.current_user_procedure.status]}}</div>
        </el-table-column>

        <el-table-column v-slot="scope" width="100">
          <el-tooltip
            content="Иерархия"
            placement="top"
            :open-delay="500"
          >
            <router-link
              :to="{ name: 'CompensationProcedureTree', params: { id: scope.row.id } }"
              target="_blank"
            >
              <el-button
                class="table-action-button"
                round
                size="mini"
                icon="fas fa-users"
              >
              </el-button>
            </router-link>
          </el-tooltip>

        </el-table-column>
      </el-table>


      <paginator
        :paginator.sync="paginator"
        use-router-query-param
        @page-changed="loadProcedures"
      ></paginator>

    </el-card>
  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";

export default {
  name: 'CompensationProcedurePlans',
  components: {
    Paginator,
    PageHeading,
  },
  mixins: [paginator, sorter],
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  data() {
    return {
      loading: false,
      procedures: [],
      procedureTypes: this.$companyConfiguration.settings.getEnum('compensation_procedure_types'),
      procedureStatuses: this.$companyConfiguration.settings.getEnum('compensation_procedure_statuses'),

    }
  },
  mounted() {
    this.loadProcedures();
  },
  methods: {
    loadProcedures() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/search-plans', {

        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: this.paginator.page,
        [this.paginator.paramPerPage]: this.paginator.perPage,
        expand: ['current_user_procedure', 'employees_of_current_user_count'].join(),
      })
        .then(({data, paginator, sorter}) => {
          this.procedures = data.procedures;
          this.paginatorFill(paginator);
          this.sorterFill(sorter);
        })
        .finally(() => {
          this.loading = false;
        });
    },


  }
}
</script>

<style scoped lang="scss"></style>