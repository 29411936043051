<template>
  <div v-loading="loading">

    <el-table
      size="mini"
      style="width: 100%;"
      :show-header="false"
      :data="attachments"
      empty-text="Нет файлов"
    >
      <el-table-column>
        <template v-slot="scope">
          <el-input
            size="small"
            v-model="scope.row.desc"
            @change="saveAttachment(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column width="500">
        <template v-slot="scope">
          <ImportUpload
            style="margin-top: 5px"
            tight
            entity="home_files"
            :data="{type: type, id: scope.row.id}"
            @import-finished="loadAttachments"
          ></ImportUpload>
        </template>
      </el-table-column>
      <el-table-column >
        <template v-slot="scope">
          {{scope.row.filename}}
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template v-slot="scope">
          <el-popconfirm
            v-if="scope.row.id"
            confirm-button-text='Да'
            cancel-button-text='нет'
            icon="el-icon-question"
            title="Вы уверены, что хотите удалить строку?"
            @confirm="deleteAttachment(scope.row)"
          >
            <el-button
              slot="reference"
              class="table-action-button"
              round
              icon="far fa-trash-alt"
            ></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>


    <el-button
      type="text"
      icon="fas fa-plus"
      @click="addAttachment"
    >
      Добавить
    </el-button>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ImportUpload from "@/components/exportImport/ImportUpload.vue";
import requestSender from "@/api/base/requestSender";
import ClickToEdit from "@/components/ClickToEdit.vue";
import Vue from "vue";

export default {
  name: "home-settings-files",
  components: {ClickToEdit, ImportUpload},

  props: {
    type: {type: String, required: true},
  },

  data() {
    return {
      loading: false,
      attachments: [],
    }
  },
  mounted() {
    this.loadAttachments();
  },
  watch: {},
  methods: {
    loadAttachments(){
      this.loading = true;

      requestSender('get', 'home/get-attachments', {
        type: this.type,
      })
        .then(data => {
          this.attachments = data.attachments;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addAttachment(){
      this.attachments.push({
        type: this.type
      });
    },

    saveAttachment(attachment){
      this.loading = true;

      requestSender('post', 'home/save-attachment-data', {
        id: attachment.id,
        type: this.type,
        desc: attachment.desc,
      })
        .then(data => {
          Vue.set(attachment, 'id', data.attachment.id);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteAttachment(attachment){
      this.loading = true;

      requestSender('post', 'home/delete-attachment', {
        id: attachment.id,
      })
        .then(data => {
          this.loadAttachments();
        })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/css/project-variables";

</style>