<template>
  <div
    v-if="$canAndRulesPass(user, 'compensations.report_see_in_menu')"
    v-loading="loading"
  >
    <page-heading
      heading="Отчеты"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>


    <div style="display: flex; justify-content: space-between">
      <div>
        <el-select
          v-model="procedureType"
          placeholder="Тип процедуры"
          no-data-text="Нет доступных типов"
        >
          <el-option
            v-for="(typeName, typeId) in filteredProcedureTypes"
            :key="typeId"
            :label="typeName"
            :value="typeId"
          >
          </el-option>
        </el-select>

        <el-select
          v-model="procedureId"
          style="margin-left: 40px"
          placeholder="Название процедуры"
          no-data-text="Нет доступных процедур"
        >
          <el-option
            v-for="procedure in filteredProcedures"
            :key="procedure.id"
            :label="procedure.name"
            :value="procedure.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button
          v-if="$canAndRulesPass(user, 'compensations.report_export')"
          :disabled="!procedureId"
          style="margin-left:35px"
          class="button-icon-green"
          type="text"
          icon="fas fa-file-excel"
          :loading="loadingXls"
          @click="exportToExcel"
        >
          Экспорт в XLSX
        </el-button>
      </div>
    </div>

    <div
      v-if="!procedureId"
      style="margin-top: 30px"
    >
      <i class="fas fa-info-circle"></i>
      Для просмотра отчета выберите название процедуры
    </div>


    <component
      style="margin-top: 30px"
      v-if="procedureReportTemplate"
      :is="procedureReportTemplate"
      :procedure-id="procedureId"
      :evaluations="evaluations"
    ></component>

  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";
import DeletedCardsModal from "@/components/cards/DeletedCardsModal.vue";
import CompensationProcedureCreateModal
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureCreateModal.vue";
import CompensationProcedureEditBaseSettings
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureEditBaseSettings.vue";
import {downloader} from "@/mixins/downloader";

export default {
  name: 'CompensationProcedureReport',
  components: {
    PageHeading,
  },
  mixins: [paginator, sorter, downloader],
  computed: {
    ...mapGetters(['user']),
    filteredProcedures() {
      if (!this.procedureType) {
        return this.procedures;
      }
      return this.procedures.filter(p => p.type === this.procedureType);
    },
    filteredProcedureTypes() {
      return Object.keys(this.$companyConfiguration.settings.getEnum('compensation_procedure_types'))
        .filter(key => this.procedures.find(p => p.type===key))
        .reduce((obj, key) => {
          obj[key] = this.$companyConfiguration.settings.getEnum('compensation_procedure_types')[key];
          return obj;
        }, {});
    },

    procedureReportTemplate() {
      if (!this.procedureId) {
        return null;
      }
      let procedure = this.procedures.find(p => p.id === this.procedureId);
      if (!procedure) {
        return null;
      }
      return () => import(`@/componentsCompany/smz/compensation/procedures/reportTemplates/${procedure.type}`);
    },
  },

  data() {
    return {
      loading: false,
      loadingXls: false,

      procedureType: null,
      procedureId: null,

      evaluations: {},
      procedures: [],


    }
  },
  mounted() {
    this.getEvaluations();
    this.loadProcedures();
  },
  methods: {
    getEvaluations() {
      requestSender('get', 'evaluation/search')
        .then(({evaluations}) => {
          this.evaluations = evaluations;
        })
    },

    loadProcedures() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/search-for-reports', {
        [this.sorter.param]: this.sorter.sort,
        [this.paginator.param]: 1,
        [this.paginator.paramPerPage]: 400,
      })
        .then(({data, paginator, sorter}) => {
          this.procedures = data.procedures;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    exportToExcel() {
      this.loadingXls = true;

      this.downloadFile(
        false,
        this.$api.exportImport.export,
        {
          entity: 'comp_plan',
          data: {
            procedure_id: this.procedureId,
          }
        }
      )
        .finally(()=>{
          this.loadingXls = false;
        })
    },




  }
}
</script>

<style scoped lang="scss"></style>