<template>
  <div
    v-if="$canAndRulesPass(procedure, 'compensations.procedure_tree_see_in_menu')"
    v-loading="loading"
  >

    <page-heading
      :heading="procedure.name"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>

    <div style="display: flex; justify-content: space-between" class="compensation-procedure-tree-agree-buttons">
      <div>
        <el-button
          v-if="$canAndRulesPass(procedure, 'compensations.procedure_agree_by_gendir')"
          type="success"
          size="small"
          :loading="loadingAgree"
          @click="agreeByGendir"
        >
          Согласовать весь завод
        </el-button>
        <el-button
          v-if="$canAndRulesPass(procedure, 'compensations.procedure_agree_by_shareholder')"
          :loading="loadingAgree"
          size="small"
          type="success"
          @click="agreeByShareholder"
        >
          Утвердить весь завод
        </el-button>
      </div>
      <div>
        <el-tag v-if="procedure.agreed_by_gendir" type="primary">Согласовано Генеральным директором АО СМЗ</el-tag>
        <el-tag v-if="procedure.agreed_by_shareholder" type="primary">Утверждено Акционером</el-tag>
      </div>
    </div>

    <div
      v-if="!$canAndRulesPass(user, 'compensations.procedure_tree_see_all')"
      style="margin: 30px 0"
    >
      <i class="fas fa-info-circle"></i>
      <span v-if="procedure.type === 'year_bonus'">Нажмите на ФИО Руководителя для просмотра годового бонуса его подчиненных.</span>
      <span v-else>Нажмите на ФИО подчиненного руководителя для просмотра его плана.</span>

    </div>

    <el-tree
      class="procedure-users-tree"
      empty-text="Нет пользователей"
      :load="loadTreeNode"
      lazy
      ref="procedureUsersTree"
      node-key="id"
      :expand-on-click-node="false"
      v-loading="loadingRootUser"
    >
      <div class="procedure-users-tree-node" slot-scope="{ node, data }">
        <div class="procedure-users-tree-node-name" @click="userClicked(data)">
          <div class="procedure-users-tree-node-name-position">{{ data.user.position }}</div>
          <div class="procedure-users-tree-node-name-full-name">{{ data.user.full_name }}</div>
        </div>
        <div class="procedure-users-tree-node-status">
          <div v-if="procedure.type === 'year_bonus'">
            <el-tag v-if="data.status === 2" type="success">Ознакомлен</el-tag>
            <el-tag v-else type="danger">Не ознакомлен</el-tag>
          </div>
          <div v-if="procedure.type === 'raise_rss' || procedure.type === 'raise_workers'">
            <el-tag v-if="data.status === 2" type="danger" style="background-color: #CD6366">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 1" type="info" style="background-color: #ECECEC">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 3"  style="background-color: #DAAF45">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 4" type="success" style="background-color: #448759">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
          </div>
        </div>
        <div>
          <div
            v-if="procedure.type === 'raise_rss' || procedure.type === 'raise_workers'"
            style="margin-top: 5px"
          >
            <el-tooltip
              v-if="$canAndRulesPass(data, procedure.type + '_user.agree_negotiations')"
              content="Согласовать план прямого подчиненного"
              placement="top"
              :open-delay="500"
            >
              <el-button
                size="small"
                round
                type="success"
                :loading="loadingAgree"
                icon="fas fa-check"
                @click="setStatus(node, procedure.type, data.user_id, 4)"
              ></el-button>
            </el-tooltip>

            <el-tooltip
              v-if="$canAndRulesPass(data, procedure.type + '_user.reject_negotiations')"
              :content="data.user_id === user.id ? 'Отозвать с согласования' : 'Отправить на доработку план прямого подчиненного'"
              placement="top"
              :open-delay="500"
            >
              <el-button
                size="small"
                round
                type="danger"
                icon="fas fa-arrow-left"
                :loading="loadingAgree"
                @click="setStatus(node, procedure.type, data.user_id, 2)"
              ></el-button>
            </el-tooltip>
          </div>

        </div>
      </div>
    </el-tree>


  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: 'CompensationProcedureTree',
  components: {
    PageHeading,
  },
  mixins: [],
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  data() {
    return {
      loading: false,
      loadingRootUser: false,

      procedure: {},

      loadingAgree: false,

    }
  },
  mounted() {
    this.loadProcedure();
  },
  methods: {
    loadProcedure() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get', {
        id: parseInt(this.$route.params.id),
        expand: ['meta', 'permissions'].join(),
      })
        .then(data => {
          this.procedure = data.procedure;
        })
        .finally(() => {
          this.loading = false;
        });
    },


    loadTreeNode(node, resolve) {
      this.loadingRootUser = !node.data;
      requestSender('get', 'compensation-procedure/get-users-tree', {
        procedure_id: this.$route.params.id,
        parent_user_id: node.data ? node.data.user_id : null,
        expand: ['user', 'permissions'].join(),
      })
        .then(data => {
          this.loadingRootUser = false;
          return resolve(data.procedure_owners)
        })
    },
    userClicked(node, nodeOfTree, treeNode) {
      if( this.$canAndRulesPass(this.user, 'compensations.procedure_tree_see_all') ){
        return;
      }
      const { href } = this.$router.resolve({ name: 'CompensationProcedureView', params: { procedure_id: this.$route.params.id, owner_id:node.user_id } } );
      window.open(href, '_blank');
    },

    setStatus(node, procedureType, userId, status){
      this.loadingAgree = true;

      const controller = procedureType === 'raise_rss' ? 'compensation-procedure-raise-rss' : 'compensation-procedure-raise-workers';
      requestSender('post', controller + '/set-status', {
        procedure_id: parseInt(this.$route.params.id),
        owner_id: userId,
        status: status,
      })
        .finally(() => {
          this.loadingAgree = false;
          node.parent.loaded = false;
          node.parent.expand();
        });
    },

    agreeByGendir(){
      this.loadingAgree = true;

      requestSender('post', 'compensation-procedure/agree-by-gendir', {
        id: parseInt(this.$route.params.id)
      })
        .finally(() => {
          this.loadingAgree = false;
          this.loadProcedure();
        });
    },
    agreeByShareholder(){
      this.loadingAgree = true;

      requestSender('post', 'compensation-procedure/agree-by-shareholder', {
        id: parseInt(this.$route.params.id)
      })
        .finally(() => {
          this.loadingAgree = false;
          this.loadProcedure();
        });
    },

  }
}
</script>

<style lang="scss">
.procedure-users-tree{

  .el-tree-node{
    padding:16px 0;
  }

  &-node{
    display: flex;
    justify-content: space-between;
    gap: 40px;

    &-name{
      margin-left: 10px;

      &-position{
        font-size: .9rem;
        color: gray;
      }
      &-full-name{
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    &-status{
      margin-top: 14px;
    }
  }

  & .el-tree-node__content{
    height: 50px !important;

    & .el-tree-node__expand-icon{
      font-size: 25px;
    }
  }

  //&-node{
  //  height:100px;
  //}
}
</style>